<template lang="html">


<div class='overlay'>
  <div class="information">
    <h1>Quality defines US.</h1>

    <p>Mi smo domaća konsultantska kuća sa metodologijom koja se primenjuje u najvećim domaćim i stranim kompanijama. Pružamo usluge iz oblasti HR  menadžmenta, Organizacionog razvoja, Procene kadrova i menadžerskog potencijala, Performance managementa, Executive search-a, Upravljanja talentima u organizaciji i Strateškog upravljanja HR funkcijom u kompanijama, sve u cilju uspešnog uvođenja promena i osiguravanja kontinuiteta u poslovnim rezultatima naših klijenata.</p>
    <p>Svojim klijentima nudimo najkvalitetnija, inovativna i praktična rešenja iz oblasti upravljanja ljudskim resursima i organizacionog razvoja, gradeći dugoročan partnerski odnos.</p>
    <p>Naša Vizija je da, uz naš jedinstven pristup i podršku  vrhunskog tima profesionalaca, inspirišemo svoje klijente na postizanje vrhunskih rezultata.</p>
    <p>U odeljku KS USLUGE imate mogućnost slanja direktnog upita našoj Kompaniji za određene usluge popunjavanjem formulara.</p>
    <p>U odeljku KS KANDIDATI možete nam poslati Vašu radnu biografiju koju ćemo sačuvati u našoj bazu kako bismo Vas kontaktirali ukoliko se otvori pozicija koja odgovara Vašem profilu.</p>
  </div>

</div>





</template>

<script>
// @ is an alias to /src
import {mapActions} from 'vuex';

export default {
  name: 'Home',
  components: {

  },
  methods:{
    ...mapActions(['setSrb']),
  },
  created(){
    this.setSrb();
  },
  beforeCreate: function() {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      document.body.className = 'homeMobile';
    } else {
      document.body.className = 'home';
    }
  }
}
</script>
<style lang="css" scoped>
.overlay {
    position: absolute;
    width: 100%;
    height: auto;
    left: 0;


  }
  .information{
    width:80%;
    color:white;
    text-align: center;
    margin: auto;
    background-color: #335577;
    border-radius: 50px;
    margin-top: 50px;
    padding: 5px
  }



</style>
