import { createStore } from 'vuex'
import Auth from '../Warehouse/Auth'; //dodato



export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Auth,
}

})
