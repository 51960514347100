// eslint-disable-next-line no-unused-vars
import axios from "axios";
// eslint-disable-next-line no-unused-vars
import router from "../router/index";

const state = {
  token: localStorage.getItem("token") || "",
  language: "srb",
  user: {},
  users: [],
  status: "",
  vqtests: {},
  feedbackTests: {},
  stavoviZaposlenihs: {},
  testLicnostis: {},
  companys: [],
  usersSameCompany: [],
  ismobile: false,
  formulari: [],
  planners: [],
  psiholoskaProcenas: [],
  faktures: [],
  candidates: [],
};

const getters = {
  // isLoggedIn: function(state){
  //   if(state.token !='' ){
  //     return true
  //   }else{
  //     return false
  //   }
  // }
  formulari: (state) => state.formulari,
  planners: (state) => state.planners,
  psiholoskaProcenas: (state) => state.psiholoskaProcenas,
  faktures: (state) => state.faktures,
  candidates: (state) => state.candidates,
  testLicnostis: (state) => state.testLicnostis,
  stavoviZaposlenihs: (state) => state.stavoviZaposlenihs,
  feedbackTests: (state) => state.feedbackTests,
  vqtests: (state) => state.vqtests,
  companys: (state) => state.companys,
  isAdmin: function(state) {
    if (state.user) {
      if (state.user.type == "admin") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },
  isLoggedIn: (state) => !!state.token, //odozgo kod samo skracen
  authState: (state) => state.status,
  user: (state) => state.user,
  users: (state) => state.users,
  usersSameCompany: (state) => state.usersSameCompany,
  ismobile: (state) => state.ismobile,

  isEng: function(state) {
    if (state.language == "eng") {
      return true;
    } else {
      return false;
    }
  },
};

// FOR DEV, EVERY ROUTE SHOULD BE http://localhost:5000/api
// FOR PRODUCTION, EVERY ROUTE SHOULD BE JUST /api
const actions = {
  setMobile({ commit }, bool) {
    commit("set_mobile", bool);
  },

  setEng({ commit }) {
    commit("set_eng");
  },
  setSrb({ commit }) {
    commit("set_srb");
  },
  //Login action
  // eslint-disable-next-line no-unused-vars
  async login({ commit }, user) {
    commit("auth_request");
    try {
      let res = await axios.post("/api/users/login", user);
      if (res.data.success) {
        // eslint-disable-next-line no-unused-vars
        const token = res.data.token;
        // eslint-disable-next-line no-unused-vars
        const user = res.data.user;
        //Store the token into the local localStorage
        localStorage.setItem("token", token);

        localStorage.setItem("user", user);
        //Set the axios defaults
        axios.defaults.headers.common["Authorization"] = token;
        commit("auth_success", token, user);
      }
      return res;
    } catch (e) {
      // empty
    } finally {
      // empty
    }
  },

  //Post ServiceAplication
  async submitServiceAplication({ commit }, data) {
    commit("auth_request");
    let res = await axios.post("/api/serviceAplications/submit", data);
    if (res.data.success) {
      commit("success_submit");
    }
    return res;
  },

  //Post procenaPsiholoska
  async submitPsiholoskaProcena({ commit }, data) {
    commit("auth_request");
    let res = await axios.post("/api/psiholoskaProcenas/submit", data);
    if (res.data.success) {
      commit("success_submit");
    }
    return res;
  },

  //LOck Planner
  async lockPlanner({ commit }, data) {
    commit("auth_request");
    let res = await axios.post(`/api/mesecPlaners/lockMesec/${data.id}`, data);
    if (res.data.success) {
      commit("success_submit");
    }
    return res;
  },

  //Post Faktura
  async submitFaktura({ commit }, data) {
    commit("auth_request");
    let res = await axios.post("/api/faktures/submit", data);
    if (res.data.success) {
      commit("success_submit");
    }
    return res;
  },

  //Post Candidates
  async submitCandidates({ commit }, data) {
    commit("auth_request");
    let res = await axios.post("/api/candidates/submit", data);
    if (res.data.success) {
      commit("success_submit");
    }
    return res;
  },

  //Post ServiceAplication
  async submitMesecPlanner({ commit }, data) {
    commit("auth_request");
    let res = await axios.post("/api/mesecPlaners/submit", data);
    if (res.data.success) {
      commit("success_submit");
    }
    return res;
  },
  //Register user
  async register({ commit }, userData) {
    commit("register_request");
    let res = await axios.post("/api/users/register", userData);
    if (res.data.success !== undefined) {
      commit("register_success");
    }
    return res;
  },

  //Register user
  async changePass({ commit }, userData) {
    commit("register_request");
    let res = await axios.put("/api/users/changePass", userData);
    if (res.data.success !== undefined) {
      commit("register_success");
    }
    return res;
  },
  async changeCandidate({ commit }, candidateData) {
    commit("register_request");
    let res = await axios.put(
      `/api/candidates/changeCandidate/${candidateData._id}`,
      candidateData
    );
    if (res.data.success !== undefined) {
      commit("register_success");
    }
    return res;
  },
  async deleteUser({ commit }, userID) {
    commit("register_request");
    let res = await axios.delete(`/api/users/deleteUser/${userID}`);
    if (res.data.success !== undefined) {
      commit("register_success");
    }
    return res;
  },
  async deleteCandidate({ commit }, candidateId) {
    commit("register_request");
    let res = await axios.delete(
      `/api/candidates/deleteCandidate/${candidateId}`
    );
    if (res.data.success !== undefined) {
      commit("register_success");
    }
    return res;
  },
  async deleteServiceApplication({ commit }, formularID) {
    commit("register_request");
    let res = await axios.delete(
      `/api/serviceAplications/delete/${formularID}`
    );
    if (res.data.success !== undefined) {
      commit("register_success");
    }
    return res;
  },
  async registerCompany({ commit }, companyData) {
    commit("registerCompany_request");
    let res = await axios.post("/api/companys/register", companyData);
    if (res.data.success !== undefined) {
      commit("registerCompany_success");
    }
    return res;
  },
  async registerSector({ commit }, sectorData) {
    commit("registerCompany_request");
    let res = await axios.put(
      `/api/companys/putSector/${sectorData.companyName}`,
      sectorData
    );
    if (res.data.success !== undefined) {
      commit("registerCompany_success");
    }
    return res;
  },

  async addNewEval({ commit }, kpiData) {
    commit("registerCompany_request");
    let res = await axios.put(`/api/companys/neweval/${kpiData.companyName}`);
    if (res.data.success !== undefined) {
      commit("registerCompany_success");
    }
    return res;
  },
  //REGISTER EDUCATION EVAL
  async administerEducationEval({ commit }, data) {
    commit("registerCompany_request");
    let res = await axios.put(
      `/api/companys/putEducationEval/${data.companyName}`,
      data
    );
    if (res.data.success !== undefined) {
      commit("registerCompany_success");
    }
    return res;
  },
  //PUT EDUCATION EVAL
  async fillEducationEval({ commit }, data) {
    commit("registerCompany_request");
    let res = await axios.put(
      `/api/companys/putEducationEvalUser/${data.companyName}`,
      data
    );
    if (res.data.success !== undefined) {
      commit("registerCompany_success");
    }
    return res;
  },
  async registerRadnoMesto({ commit }, rMData) {
    commit("registerCompany_request");
    let res = await axios.put(`/api/companys/putRM`, rMData);
    if (res.data.success !== undefined) {
      commit("registerCompany_success");
    }
    return res;
  },

  //Put nadredjeni
  async setNadredjeni({ commit }, nadredjeniData) {
    commit("registerCompany_request");

    try {
      let res = await axios.put(
        `/api/users/putNadredjeni/${nadredjeniData.userID}`,
        nadredjeniData
      );
      //res = await axios.put(`/api/users/setPerformanceEvaluationPodredjeni/${nadredjeniData.nadredjeni_id}`, nadredjeniData);
      if (res.data.success !== undefined) {
        commit("registerCompany_success");
      }
      return res;
    } catch (e) {
      // empty
    } finally {
      // empty
    }
  },

  //updateUSER
  async updateUser({ commit }, userData) {
    commit("registerCompany_request");
    let res = await axios.put(
      `/api/users/updateUser/${userData.userID}`,
      userData
    );
    if (res.data.success !== undefined) {
      commit("registerCompany_success");
    }
    return res;
  },
  //Put Custom questionnaire Company
  async setCompanyCustomQuestionnaire({ commit }, questionnaireData) {
    commit("registerCompany_request");
    let res = await axios.put(
      `/api/companys/putCustomQuestionnaire/${questionnaireData.companyName}`,
      questionnaireData
    );
    if (res.data.success !== undefined) {
      commit("registerCompany_success");
    }
    return res;
  },
  //Edit Custom questionnaire Company
  async editActiveCompanyCustomQuestionnaire({ commit }, questionnaireData) {
    commit("registerCompany_request");
    let res = await axios.put(
      `/api/companys/editCustomQuestionnaire/status/${questionnaireData.companyName}`,
      questionnaireData
    );
    if (res.data.success !== undefined) {
      commit("registerCompany_success");
    }
    return res;
  },
  //Put Custom Procena Company
  async setCompanyCustomProcena({ commit }, ProcenaData) {
    commit("registerCompany_request");
    let res = await axios.put(
      `/api/companys/putCustomProcena/${ProcenaData.companyName}`,
      ProcenaData
    );
    if (res.data.success !== undefined) {
      commit("registerCompany_success");
    }
    return res;
  },
  //Edit Custom Procena Company
  async editActiveCompanyCustomProcena({ commit }, procenaData) {
    commit("registerCompany_request");
    let res = await axios.put(
      `/api/companys/editCustomProcena/status/${procenaData.companyName}`,
      procenaData
    );
    if (res.data.success !== undefined) {
      commit("registerCompany_success");
    }
    return res;
  },
  //Put Kpi Company
  async setCompanyKpi({ commit }, kpiData) {
    commit("registerCompany_request");
    let res = await axios.put(
      `/api/companys/putKpi/${kpiData.companyName}`,
      kpiData
    );
    if (res.data.success !== undefined) {
      commit("registerCompany_success");
    }
    return res;
  },
  //Put Kpi Licni
  async setLicniKpi({ commit }, kpiData) {
    commit("registerCompany_request");
    let res = await axios.put(
      `/api/users/setLicniKpi/${kpiData.userId}`,
      kpiData
    );
    if (res.data.success !== undefined) {
      commit("registerCompany_success");
    }
    return res;
  },
  async addPerformanceEval({ commit }, kpiData) {
    commit("registerCompany_request");
    let res = await axios.put(
      `/api/users/addPerformanceEval/${kpiData.userId}`,
      kpiData
    );
    if (res.data.success !== undefined) {
      commit("registerCompany_success");
    }
    return res;
  },

  async addPerformanceEvalLicni({ commit }, kpiData) {
    commit("registerCompany_request");
    let res = await axios.put(
      `/api/users/addPerformanceEvalLicni/${kpiData.userId}`,
      kpiData
    );
    if (res.data.success !== undefined) {
      commit("registerCompany_success");
    }
    return res;
  },
  //Put Kpi Licni SamoProcena
  async setLicniKpiSamoProcena({ commit }, kpiData) {
    commit("registerCompany_request");
    let res = await axios.put(
      `/api/users/setKpiSamoProcena/${kpiData.userId}`,
      kpiData
    );
    // res = await axios.put(`/api/users/setPerformanceEvaluationPodredjeni/${kpiData.nadredjeni._id}`, kpiData);
    if (res.data.success !== undefined) {
      commit("registerCompany_success");
    }
    return res;
  },
  //Put Kpi Konacna Procena
  async setKpiKonacnaProcena({ commit }, kpiData) {
    commit("registerCompany_request");
    let res = await axios.put(
      `/api/users/setKpiKonacnaProcena/${kpiData.userId}`,
      kpiData
    );
    if (res.data.success !== undefined) {
      commit("registerCompany_success");
    }
    return res;
  },
  //Get profile
  async getProfile({ commit }) {
    commit("profile_request");

    let token = localStorage.getItem("token");

    let user = localStorage.getItem("user");
    //Set the axios defaults
    axios.defaults.headers.common["Authorization"] = token;
    commit("auth_success", token, user);

    let res = await axios.get("/api/users/profile");
    commit("profile_present", res.data.user);
    return res;
  },

  //Logout
  async logout({ commit }) {
    await localStorage.removeItem("token");
    commit("logout");
    delete axios.defaults.headers.common["Authorization"];
    router.push("/login");
    return;
  },
  //Submit VQTest
  async submitVQ({ commit }, vqData) {
    commit("vq_request");
    let res = await axios.put(`/api/users/putVQ/${vqData.id}`, vqData);
    if (res.data.success !== undefined) {
      commit("submitVQ_success");
    }
    return res;
  },
  //SUBMIT BELBIN
  async submitBelbin({ commit }, belbinData) {
    commit("vq_request");
    let res = await axios.put(
      `/api/users/putBelbin/${belbinData.id}`,
      belbinData
    );
    if (res.data.success !== undefined) {
      commit("submitVQ_success");
    }
    return res;
  },
  //SUBMIT procenakandidata
  async submitProcenaKandidata({ commit }, procenaData) {
    commit("vq_request");
    let res = await axios.put(
      `/api/users/putProcenaKandidata/${procenaData.id}`,
      procenaData
    );
    if (res.data.success !== undefined) {
      commit("submitVQ_success");
    }
    return res;
  },
  async submitFeedBackTest360({ commit }, feedbackData) {
    commit("feedbacktest_request");
    let res = await axios.put(
      `/api/users/putFeedBack/${feedbackData.id}`,
      feedbackData
    );
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },
  async submitStavoviZaposlenih({ commit }, stavoviZaposlenihData) {
    commit("feedbacktest_request");
    let res = await axios.put(
      `/api/companys/putStavoviZaposlenih/${stavoviZaposlenihData.companyName}`,
      stavoviZaposlenihData
    );
    res = await axios.put(
      `/api/users/finishedStavoviZaposlenih/${stavoviZaposlenihData.userId}`
    );
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },

  async submitTestLicnosti({ commit }, testLicnostiData) {
    commit("feedbacktest_request");
    let res = await axios.put(
      `/api/users/putTestLicnosti/${testLicnostiData.userId}`,
      testLicnostiData
    );
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },
  //GET ALL USERS
  async getAllUsers({
    //art
    commit,
  }) {
    commit("users_request");
    let res = await axios.get("/api/users/getAll");
    commit("users", res.data);
    return res;
  },

  //GET ALL procenas
  async getAllPsiholoskaProcena({
    //art
    commit,
  }) {
    commit("users_request");
    let res = await axios.get("/api/psiholoskaProcenas/getAll");
    commit("psiholoskaProcenas", res.data);
    return res;
  },
  //GET PLANNERS
  async getAllPlanners({ commit }) {
    commit("users_request");
    let res = await axios.get("/api/mesecPlaners/getAll");
    commit("planners", res.data);
    return res;
  },
  //GET Formulars
  async getAllFormulars({
    //art
    commit,
  }) {
    commit("users_request");
    let res = await axios.get("/api/serviceAplications/getAll");
    commit("formulars", res.data);
    return res;
  },
  //GET all Faktures
  async getAllFaktures({
    //art
    commit,
  }) {
    commit("users_request");
    let res = await axios.get("/api/faktures/getAll");
    commit("faktures", res.data);
    return res;
  },
  //GET all Candidates
  async getAllCandidates({ commit }, data) {
    commit("users_request");
    let res = await axios.get(
      `/api/candidates/getAll${
        data ? "/" + data.searchString + "/" + data.searchField : ""
      }`
    );
    commit("candidates", res.data);
    return res;
  },
  //GET Users FROM SAME COMPANY
  async getCollegues(
    {
      //art
      commit,
    },
    companyName
  ) {
    commit("users_request");
    let res = await axios.get(`/api/users/getCollegues/${companyName}`);
    commit("usersSameCompany", res.data);
    return res;
  },
  //Get items
  async getVQTests({
    //art
    commit,
  }) {
    commit("vqtests_request");
    let res = await axios.get("/api/vqtests/getAll");
    commit("vqtests", res.data);
    return res;
  },
  async getFeedBackTests({
    //art
    commit,
  }) {
    commit("feedbacktest_request");
    let res = await axios.get("/api/feedbackTests/getAll");
    commit("feedbacktests", res.data);
    return res;
  },
  async getStavoviZaposlenih({
    //art
    commit,
  }) {
    commit("feedbacktest_request");
    let res = await axios.get("/api/stavoviZaposlenihs/getAll");
    commit("stavoviZaposlenih", res.data);
    return res;
  },
  async getTestLicnosti({
    //art
    commit,
  }) {
    commit("feedbacktest_request");
    let res = await axios.get("/api/testLicnostis/getAll");
    commit("testLicnosti", res.data);
    return res;
  },
  //Get companies
  async getCompanies({
    //art
    commit,
  }) {
    commit("companies_request");
    let res = await axios.get("/api/companys/getAll");
    commit("companies", res.data);
    return res;
  },

  //Administer VQ
  async administerVQTest({ commit }, userID) {
    commit("feedbacktest_request");
    let res = await axios.put(`/api/users/administerVQ/${userID}`);
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },
  //RESET KPI
  async resetKpi({ commit }, userID) {
    commit("feedbacktest_request");
    let res = await axios.put(`/api/users/resetKpi/${userID}`);
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },
  //Administer procenaPregled
  async administerProcenaIzmena({ commit }, userID) {
    commit("feedbacktest_request");
    let res = await axios.put(`/api/users/administerProcenaIzmena/${userID}`);
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },
  //Administer procenaPregled deny
  async administerProcenaIzmenaDeny({ commit }, userID) {
    commit("feedbacktest_request");
    let res = await axios.put(
      `/api/users/administerProcenaIzmenaDeny/${userID}`
    );
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },
  //Administer kpi istorija
  async administerKpiHistoryPregled({ commit }, userID) {
    commit("feedbacktest_request");
    let res = await axios.put(`/api/users/administerKpiHistory/${userID}`);
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },

  //Administer kpi istorija deny
  async administerKpiHistoryPregledDeny({ commit }, userID) {
    commit("feedbacktest_request");
    let res = await axios.put(`/api/users/administerKpiHistoryDeny/${userID}`);
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },

  //Administer PDP Pregled
  async administerPDPPregled({ commit }, userID) {
    commit("feedbacktest_request");
    let res = await axios.put(`/api/users/administerPDP/${userID}`);
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },
  //Administer PDP Pregled Deny
  async administerPDPPregledDeny({ commit }, userID) {
    commit("feedbacktest_request");
    let res = await axios.put(`/api/users/administerPDPDeny/${userID}`);
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },
  //Administer PDP Pregled ALL
  async administerPDPPregledAll({ commit }, userID) {
    commit("feedbacktest_request");
    let res = await axios.put(`/api/users/administerPDPAll/${userID}`);
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },
  //Administer PDP Pregled ALL Deny
  async administerPDPPregledAllDeny({ commit }, userID) {
    commit("feedbacktest_request");
    let res = await axios.put(`/api/users/administerPDPAllDeny/${userID}`);
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },

  //Administer PDP Izmena
  async administerPDPIzmena({ commit }, userID) {
    commit("feedbacktest_request");
    let res = await axios.put(`/api/users/administerPDPIzmena/${userID}`);
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },
  //Administer PDP Izmena Deny
  async administerPDPIzmenaDeny({ commit }, userID) {
    commit("feedbacktest_request");
    let res = await axios.put(`/api/users/administerPDPIzmenaDeny/${userID}`);
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },
  //Custom Questionnaire save answers
  async customQuestionnaireSaveAnswers({ commit }, data) {
    commit("feedbacktest_request");
    let res = await axios.put(`/api/users/questionnaire-answers`, data);
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },

  //Custom Procena save answers
  async customProcenaSaveAnswers({ commit }, data) {
    commit("feedbacktest_request");
    let res = await axios.put(`/api/users/procena-answers`, data);
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },
  //Administer procenaPregled
  async administerProcenaPregled({ commit }, userID) {
    commit("feedbacktest_request");
    let res = await axios.put(`/api/users/administerProcena/${userID}`);
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },

  //Administer procenaPregled deny
  async administerProcenaPregledDeny({ commit }, userID) {
    commit("feedbacktest_request");
    let res = await axios.put(`/api/users/administerProcenaDeny/${userID}`);
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },

  //Custom procena User select view
  async administerCustomProcenaUserView({ commit }, data) {
    commit("feedbacktest_request");
    let res = await axios.put(
      `/api/users/administerProcenaUserSelectView/${data.id}`,
      data
    );
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },
  //Custom procena User select view DENY
  async administerCustomProcenaUserViewDeny({ commit }, data) {
    commit("feedbacktest_request");
    let res = await axios.put(
      `/api/users/administerProcenaUserSelectViewDeny/${data.id}`,
      data
    );
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },

  //Administer VQ
  async administerKpiPregled({ commit }, userID) {
    commit("feedbacktest_request");
    let res = await axios.put(`/api/users/administerPregledKpi/${userID}`);
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },

  //Administer VQ
  async administerKpiPregledDeny({ commit }, userID) {
    commit("feedbacktest_request");
    let res = await axios.put(`/api/users/administerPregledKpiDeny/${userID}`);
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },

  //Administer Custom Questionnaire
  async administerCustomQuestionnairePregled({ commit }, userID) {
    commit("feedbacktest_request");
    let res = await axios.put(
      `/api/users/administerPregledCustomQuestionnaire/${userID}`
    );
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },

  //Administer Custom Questionnaire
  async administerCustomQuestionnairePregledDeny({ commit }, userID) {
    commit("feedbacktest_request");
    let res = await axios.put(
      `/api/users/administerPregledCustomQuestionnaireDeny/${userID}`
    );
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },

  //PUT PLANNER
  async putPlanner({ commit }, data) {
    commit("feedbacktest_request");
    let res = await axios.put(`/api/mesecPlaners/putMesec/${data.id}`, data);
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },
  //Administer FeedBackReport
  async administerFeedBackReport({ commit }, userID) {
    commit("feedbacktest_request");
    let res = await axios.put(`/api/users/administerFeedBackReport/${userID}`);
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },
  //Administer FeedBackReport
  async administerFeedBackReportDeny({ commit }, userID) {
    commit("feedbacktest_request");
    let res = await axios.put(
      `/api/users/administerFeedBackReportDeny/${userID}`
    );
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },
  async administerVQTestDeny({ commit }, userID) {
    commit("feedbacktest_request");
    let res = await axios.put(`/api/users/administerVQdeny/${userID}`);
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },
  //Administer TL
  async administerTLTest({ commit }, userID) {
    commit("feedbacktest_request");
    let res = await axios.put(`/api/users/administerTL/${userID}`);
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },
  async administerTLTestDeny({ commit }, userID) {
    commit("feedbacktest_request");
    let res = await axios.put(`/api/users/administerTLdeny/${userID}`);
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },
  //Administer Stavovi
  async administerStavoviTest({ commit }, userID) {
    commit("feedbacktest_request");
    let res = await axios.put(`/api/users/administerStavovi/${userID}`);
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },

  async administerStavoviTestDeny({ commit }, userID) {
    commit("feedbacktest_request");
    let res = await axios.put(`/api/users/administerStavovideny/${userID}`);
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },
  //Administer 360 FB
  async administerFBTest({ commit }, userID) {
    commit("feedbacktest_request");
    let res = await axios.put(`/api/users/administerFeedback/${userID}`);
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },

  async administerFBTestDeny({ commit }, userID) {
    commit("feedbacktest_request");
    let res = await axios.put(`/api/users/administerFeedbackdeny/${userID}`);
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },
  //Administer procenaEdukacije
  async administerProcenaEdukacije({ commit }, userID) {
    commit("feedbacktest_request");
    let res = await axios.put(
      `/api/users/administerProcenaEdukacije/${userID}`
    );
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },
  async administerProcenaEdukacijeDeny({ commit }, userID) {
    commit("feedbacktest_request");
    let res = await axios.put(
      `/api/users/administerProcenaEdukacijeDeny/${userID}`
    );
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },

  //Administer belbin
  async administerBelbinTest({ commit }, userID) {
    commit("feedbacktest_request");
    let res = await axios.put(`/api/users/administerBelbin/${userID}`);
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },

  async administerBelbinTestDeny({ commit }, userID) {
    commit("feedbacktest_request");
    let res = await axios.put(`/api/users/administerBelbindeny/${userID}`);
    if (res.data.success !== undefined) {
      commit("feedbacktest_success");
    }
    return res;
  },

  //changePDP
  async changePDP({ commit }, pdpData) {
    commit("register_request");
    let res = await axios.put("/api/users/changepdp", pdpData);
    if (res.data.success !== undefined) {
      commit("register_success");
    }
    return res;
  },
};

const mutations = {
  auth_request(state) {
    state.status = "loading";
  },
  success_submit(state) {
    state.status = "success";
  },
  auth_success(state, token, user) {
    state.token = token;
    state.user = user;
    state.status = "success";
  },
  register_request(state) {
    state.status = "loading";
  },
  register_success(state) {
    state.status = "success";
  },
  registerCompany_request(state) {
    state.status = "loading";
  },
  registerCompany_success(state) {
    state.status = "success";
  },
  logout(state) {
    state.status = "";
    state.token = "";
    state.user = "";
  },
  profile_request(state) {
    state.status = "loading";
  },
  profile_present(state, user) {
    state.user = user;
  },
  users_request(state) {
    state.status = "loading";
  },
  vq_request(state) {
    state.status = "loading";
  },
  submitVQ_success(state) {
    state.status = "success";
  },
  feedbacktest_request(state) {
    state.status = "loading";
  },
  submitfeedbacktest_success(state) {
    state.status = "success";
  },
  vqtests_request(state) {
    state.status = "loading";
  },
  vqtests(state, vqtests) {
    state.vqtests = vqtests;
    state.status = "success";
  },
  feedbacktests(state, feedbackTests) {
    state.feedbackTests = feedbackTests;
    state.status = "success";
  },
  stavoviZaposlenih(state, stavoviZaposlenihs) {
    state.stavoviZaposlenihs = stavoviZaposlenihs;
    state.status = "success";
  },
  testLicnosti(state, testLicnostis) {
    state.testLicnostis = testLicnostis;
    state.status = "success";
  },
  companies_request(state) {
    state.status = "loading";
  },
  companies(state, companys) {
    state.companys = companys;
    state.status = "success";
  },
  users(state, users) {
    state.users = users;
    state.status = "success";
  },
  formulars(state, formulari) {
    state.formulari = formulari;
    state.status = "success";
  },
  planners(state, planners) {
    state.planners = planners;
    state.status = "success";
  },
  psiholoskaProcenas(state, psiholoskaProcenas) {
    state.psiholoskaProcenas = psiholoskaProcenas;
    state.status = "success";
  },
  faktures(state, faktures) {
    state.faktures = faktures;
    state.status = "success";
  },
  candidates(state, candidates) {
    state.candidates = candidates;
    state.status = "success";
  },
  usersSameCompany(state, usersSameCompany) {
    state.usersSameCompany = usersSameCompany;
    state.status = "success";
  },
  set_mobile(state, bool) {
    state.ismobile = bool;
  },
  set_eng(state) {
    state.language = "eng";
  },
  set_srb(state) {
    state.language = "srb";
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
