<template>
  <body id="bodyId">


  <div id="app">

    <Navbar/>


  <div class="container">
    <router-view/>
  </div>

</div>
</body>
</template>

<script type="text/javascript">

import Navbar from '@/components/Navbar';
import {mapActions} from 'vuex';

export default{
components:{
  Navbar
},
methods:{
  ...mapActions(['setMobile']),
},
created() {
  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    this.setMobile(false);
  } else {
    this.setMobile(true);
  }
},

};

</script>

<style>
.card{
  border-radius: 0px;
}
body.home {
  background-image: url('~@/assets/global2.jpg') ;
  background-size: cover;
}

body.kandidati {
  background-image: url('~@/assets/SkyScraper4.jpg') ;
  background-size: cover;
}
body.login {
  background: url('~@/assets/loginkey.jpg') no-repeat center center fixed;
  /* background-image: url('~@/assets/Bizniszgrade.jpg') ; */
    /* background-image: url('~@/assets/NaslovnaKSAPP.svg') ; */
   
  background-size: cover;
}
body.loginMobile {
  background: url('~@/assets/mobilelogin.jpg') no-repeat center center fixed;
  /* background-image: url('~@/assets/mobile/homeMobile.png') ; */
    /* background-image: url('~@/assets/NaslovnaKSAPP.svg') ; */
  background-size: cover;
}
body.landing {
  background-image: url('~@/assets/login.jpg') ;
    /* background-image: url('~@/assets/NaslovnaKSAPP.svg') ; */
  background-size: cover;
}
body.landingMobile {
  background: url('~@/assets/mobilehome.jpg') no-repeat center center fixed;
  /* background-image: url('~@/assets/mobile/landingMobile.jpg') ; */
    /* background-image: url('~@/assets/NaslovnaKSAPP.svg') ; */
  background-size: cover;
}
body.usluge{

  background-image: url('~@/assets/SkyScraper4.jpg') ;
  background-size: cover;
}
body.uslugePanel{
    background-color: #335577;
}
body.other{

}
body.profile{
  background-image: url('~@/assets/profile.jpg') ;
  background-size: cover;
}
body.profileMobile{
  background: url('~@/assets/profilemobile.jpg') no-repeat center center fixed;
  /* background-image: url('~@/assets/mobile/profileMobile.png') ; */
  background-size: cover;
}

body.homeMobile{
  background-image: url('~@/assets/mobile/homeMobile.png') ;
  background-size: cover;
}
</style>
