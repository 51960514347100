import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
// eslint-disable-next-line no-unused-vars
import store from '../store/index'

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: () => import('../views/Landing.vue')
  },
  {
    path: '/eng',
    name: 'LandingEng',
    component: () => import('../views/english/LandingEng.vue')
  },
  {
    path: '/global',
    name: 'Home',
    component: Home
  },
  {
    path: '/globalEng',
    name: 'HomeEng',
    component: () => import('../views/english/GlobalEng.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta:{
      requiresGuest: true
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue'),
    meta:{
      requiresGuest: false
    }
  },
  {
    path: '/registerCompany',
    name: 'RegisterCompany',
    component: () => import('../views/RegisterCompany.vue'),
    meta:{
      requiresGuest: false
    }
  },
  {
    path: '/charttest',
    name: 'ChartTest',
    component: () => import('../views/ChartTest.vue'),
    meta:{
      requiresGuest: false
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Profile.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/vqtest',
    name: 'Vqtest',
    component: () => import('../views/VQTest.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/feedBackTest',
    name: 'feedBackTest',
    component: () => import('../views/FeedBackTest.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/performanceEvaluation',
    name: 'PerformanceEvaluation',
    component: () => import('../views/PerformanceEvaluation.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/personalDevelopmentPlan',
    name: 'Personal Development Plan',
    component: () => import('../views/PersonalDevelopmentPlan.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/feedBackIzvestaj',
    name: 'FeedBackIzvestaj',
    component: () => import('../views/FeedBackIzvestajSvi.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/procenaEdukacijeIzvestaj',
    name: 'ProcenaEdukacijeIzvestaj',
    component: () => import('../components/ProcenaEdukacijeIzvestaj.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/performanceEvaluationViewOnly',
    name: 'PerformanceEvaluationViewOnly',
    component: () => import('../views/PerformanceEvaluationViewOnly.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/performanceEvaluationKonacna',
    name: 'PerformanceEvaluationKonacna',
    component: () => import('../views/PerformanceEvaluationKonacnaProcena.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/performanceEvaluationKonacnaHistory',
    name: 'PerformanceEvaluationKonacnaHistory',
    component: () => import('../views/PerformanceEvaluationKonacnaHistory.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/procena-user-admin',
    name: 'ProcenaKandidataUserAdmin',
    component: () => import('../components/ProcenaKandidataUserAdmin.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/pdp-user',
    name: 'PersonalDevelopmentPlan',
    component: () => import('../components/PersonalDevelopmentPlanWrapper.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/belbin',
    name: 'belbin',
    component: () => import('../views/Belbin.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/stavoviZaposlenih',
    name: 'stavoviZaposlenih',
    component: () => import('../views/StavoviZaposlenih.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/testLicnosti',
    name: 'TestLicnosti',
    component: () => import('../views/TestLicnosti.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/procenaEdukacije',
    name: 'ProcenaEdukacije',
    component: () => import('../views/ProcenaEdukacije.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/admin',
    name: 'Administration',
    component: () => import('../views/TestAdministration.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/results',
    name: 'AdminPage',
    component: () => import('../views/TestResults.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/ksusluge',
    name: 'KSUsluge',
    component: () => import('../views/KSUsluge.vue'),
  },
  {
    path: '/ksuslugeEng',
    name: 'KSUslugeEng',
    component: () => import('../views/english/KSUslugeEng.vue'),
  },
  {
    path: '/executiveSearch',
    name: 'ExecutiveSearch',
    component: () => import('../views/ksUsluge/ExecutiveSearch.vue'),
  },
  {
    path: '/executiveSearchEng',
    name: 'ExecutiveSearchEng',
    component: () => import('../views/english/ExecutiveSearchEng.vue'),
  },
  {
    path: '/outsourcehr',
    name: 'OutsourceHR',
    component: () => import('../views/ksUsluge/OutsourceHR.vue'),
  },
  {
    path: '/outsourcehrEng',
    name: 'OutsourceHREng',
    component: () => import('../views/english/OutsourceHREng.vue'),
  },
  {
    path: '/uvodjenjeHRfunkcije',
    name: 'UvodjenjeHRfunkcije',
    component: () => import('../views/ksUsluge/UvodjenjeHRfunkcije.vue'),
  },
  {
    path: '/uvodjenjeHRfunkcijeEng',
    name: 'UvodjenjeHRfunkcijeEng',
    component: () => import('../views/english/UvodjenjeHRfunkcijeEng.vue'),
  },
  {
    path: '/businesscoaching',
    name: 'BusinessCoaching',
    component: () => import('../views/ksUsluge/BusinessCoaching.vue'),
  },
  {
    path: '/businesscoachingEng',
    name: 'BusinessCoachingEng',
    component: () => import('../views/english/BusinessCoachingEng.vue'),
  },
  {
    path: '/analizaorganizacionogmodela',
    name: 'AnalizaOrganizacionogModela',
    component: () => import('../views/ksUsluge/AnalizaOrganizacionogModela.vue'),
  },
  {
    path: '/analizaorganizacionogmodelaEng',
    name: 'AnalizaOrganizacionogModelaEng',
    component: () => import('../views/english/AnalizaOrganizacionogModelaEng.vue'),
  },
  {
    path: '/performanceMenagement',
    name: 'PerformanceMenagement',
    component: () => import('../views/ksUsluge/PerformanceMenagement.vue'),
  },
  {
    path: '/performanceMenagementEng',
    name: 'PerformanceMenagementEng',
    component: () => import('../views/english/PerformanceMenagementEng.vue'),
  },
  {
    path: '/talentMenagement',
    name: 'TalentMenagement',
    component: () => import('../views/ksUsluge/TalentMenagement.vue'),
  },
  {
    path: '/talentMenagementEng',
    name: 'TalentMenagementEng',
    component: () => import('../views/english/TalentMenagementEng.vue'),
  },
  {
    path: '/hrProfessionalProgram',
    name: 'HRProfessionalProgram',
    component: () => import('../views/ksUsluge/HRProfessionalProgram.vue'),
  },
  {
    path: '/hrProfessionalProgramEng',
    name: 'HRProfessionalProgramEng',
    component: () => import('../views/english/HRProfessionalProgramEng.vue'),
  },
  {
    path: '/assessmentProcenaTimova',
    name: 'AssessmentProcenaTimova',
    component: () => import('../views/ksUsluge/AssessmentProcenaTimova.vue'),
  },
  {
    path: '/assessmentProcenaTimovaEng',
    name: 'AssessmentProcenaTimovaEng',
    component: () => import('../views/english/AssessmentProcenaTimovaEng.vue'),
  },
  {
    path: '/procena360',
    name: 'Procena360',
    component: () => import('../views/ksUsluge/Procena360.vue'),
  },
  {
    path: '/procena360Eng',
    name: 'Procena360Eng',
    component: () => import('../views/english/Procena360Eng.vue'),
  },
  {
    path: '/istrazivanjeStavovaZaposlenih',
    name: 'IstrazivanjeStavovaZaposlenih',
    component: () => import('../views/ksUsluge/IstrazivanjeStavovaZaposlenih.vue'),
  },
  {
    path: '/istrazivanjeStavovaZaposlenihEng',
    name: 'IstrazivanjeStavovaZaposlenihEng',
    component: () => import('../views/english/IstrazivanjeStavovaZaposlenihEng.vue'),
  },
  {
    path: '/kskandidati',
    name: 'KSKandidati',
    component: () => import('../views/KSKandidati.vue'),
  },
  {
    path: '/kskandidatiEng',
    name: 'KSKandidatiEng',
    component: () => import('../views/english/KSKandidatiEng.vue'),
  },
  {
    path: '/performanceEvaluationAdministration',
    name: 'PerformanceEvaluationAdministration',
    component: () => import('../views/PerformanceEvaluationAdministration.vue'),
  },
  {
    path: '/performanceEvaluationAdministrationLicni',
    name: 'PerformanceEvaluationAdministrationLicni',
    component: () => import('../views/PerformanceEvaluationAdministrationLicni.vue'),
  },
  {
    path: '/performanceEvaluationAdministrationNew',
    name: 'PerformanceEvaluationAdministrationNew',
    component: () => import('../views/PerformanceEvaluationAdministrationNew.vue'),
  },
  {
    path: '/questionnaire-creation',
    name: 'UpitnikKreiranje',
    component: () => import('../views/UpitnikKreiranje.vue'),
  },
  {
    path: '/questionnaire-report',
    name: 'UpitnikIzvestaj',
    component: () => import('../views/UpitnikIzvestaj.vue'),
  },
  {
    path: '/bazaKorisnika',
    name: 'BazaKorisnika',
    component: () => import('../views/BazaKorisnika.vue'),
  },
  {
    path: '/formulari',
    name: 'Formulari',
    component: () => import('../views/Formulari.vue'),
  },
  {
    path: '/planner',
    name: 'Planner',
    component: () => import('../views/Planner.vue'),
  },
  {
    path: '/faktura',
    name: 'Faktura',
    component: () => import('../views/Faktura.vue'),
  },
  {
    path: '/faktures',
    name: 'FaktureSve',
    component: () => import('../views/FaktureSve.vue'),
  },
  {
    path: '/candidates',
    name: 'Candidates',
    component: () => import('../views/Candidates.vue'),
  },
  {
    path: '/konacnaView',
    name: 'KonacnaView',
    component: () => import('../views/PerformanceEvaluationKonacnaView.vue'),
  },
  {
    path: '/psiholoskeProcene',
    name: 'PsiholoskeProcene',
    component: () => import('../views/PsiholoskeProcene.vue'),
  },
  
  {
    path: '/customPsiholoskeProcene',
    name: 'CustomPsiholoskeProcene',
    component: () => import('../components/CustomProcenaViewComponent.vue'),
  },
  {
    path: '/custom-questionnaire-user',
    name: 'CustomQuestionnaire',
    component: () => import('../components/CustomQuestionnaireComponent.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to,from,next)=>{
  if(to.matched.some(record => record.meta.requiresAuth)){
    if(!store.getters.isLoggedIn){
      // Redirect to the login page
      next('/login');
    }else{
      next();
    }
  }else if(to.matched.some(record => record.meta.requiresGuest)){
    if(store.getters.isLoggedIn){
      // Redirect to the login page
      next('/profile');
    }else{
      next();
    }
  }else{
    next();
  }
})

export default router
