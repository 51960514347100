<template lang="html">
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent1"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div
      class="collapse navbar-collapse ps-4 pt-3"
      id="navbarSupportedContent1"
    >
      <ul class="navbar-nav mr-auto">
        <li class="nav-item"></li>
        <li class="nav-item">
          <router-link
            @click="isPlannerOpened = false"
            class="navbar-brand"
            to="/"
            v-if="!isLoggedIn && !isEng"
            >Home</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            @click="isPlannerOpened = false"
            class="navbar-brand"
            to="/eng"
            v-if="!isLoggedIn && isEng"
            >Home</router-link
          >
        </li>

        <li class="nav-item">
          <router-link
            @click="isPlannerOpened = false"
            class="navbar-brand"
            to="/global"
            v-if="!isLoggedIn && !isEng"
            >Global</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            @click="isPlannerOpened = false"
            class="navbar-brand"
            to="/globalEng"
            v-if="!isLoggedIn && isEng"
            >Global</router-link
          >
        </li>

        <li class="nav-item">
          <router-link
            @click="isPlannerOpened = false"
            class="navbar-brand"
            to="/ksusluge"
            v-if="!isLoggedIn && !isEng"
            >Usluge</router-link
          >
        </li>

        <li class="nav-item">
          <router-link
            @click="isPlannerOpened = false"
            class="navbar-brand"
            to="/ksuslugeEng"
            v-if="!isLoggedIn && isEng"
            >Services</router-link
          >
        </li>

        <li class="nav-item">
          <router-link
            @click="isPlannerOpened = false"
            class="navbar-brand"
            to="/kskandidati"
            v-if="!isLoggedIn && !isEng"
            >Kandidati</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            @click="isPlannerOpened = false"
            class="navbar-brand"
            to="/kskandidatiEng"
            v-if="!isLoggedIn && isEng"
            >Candidates</router-link
          >
        </li>

        <li class="nav-item">
          <router-link
            @click="isPlannerOpened = false"
            class="navbar-brand"
            to="/login"
            v-if="!isLoggedIn"
            >Login</router-link
          >
        </li>
        <li class="nav-item ps-3 py-1">
          <router-link
            @click="isPlannerOpened = false"
            class="navbar-brand p-0 m-0"
            to="/profile"
            v-if="isLoggedIn && !isAdmin"
            >Profil</router-link
          >
        </li>

        <!--

    <li class="nav-item">
      <router-link class="navbar-brand" to="/vqtest" v-if="isLoggedIn && !isAdmin">VQTest</router-link>
</li>
    <li class="nav-item">
      <router-link class="navbar-brand" to="/feedBackTest" v-if="isLoggedIn && !isAdmin">360 FeedBack Test</router-link>
</li>
    <li class="nav-item">
      <router-link class="navbar-brand" to="/stavoviZaposlenih" v-if="isLoggedIn && !isAdmin">Stavovi Zaposlenih</router-link>
</li>
    <li class="nav-item">
      <router-link class="navbar-brand" to="/testLicnosti" v-if="isLoggedIn && !isAdmin">Test Licnosti</router-link>
</li>
-->

        <li class="nav-item">
          <router-link
            @click="isPlannerOpened = false"
            class="navbar-brand"
            to="/results"
            v-if="isAdmin"
            >Test Rezultati</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            @click="isPlannerOpened = false"
            class="navbar-brand"
            to="/admin"
            v-if="isAdmin"
            >Test Administracija</router-link
          >
        </li>

        <ul class="nav navbar-nav" v-if="isAdmin">
          <li class="nav-item">
            <a
              @click="isPlannerOpened = false"
              href="#"
              class="navbar-brand"
              data-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="true"
            >
              Registracija
            </a>
            <ul class="dropdown-menu bg-dark ps-3">
              <li class="nav-item">
                <router-link
                  @click="isPlannerOpened = false"
                  class="navbar-brand"
                  to="/register"
                  v-if="isAdmin"
                  >Registruj Zaposlenog</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  @click="isPlannerOpened = false"
                  class="navbar-brand"
                  to="/registerCompany"
                  v-if="isAdmin"
                  >Registruj Kompaniju</router-link
                >
              </li>
            </ul>
          </li>
        </ul>

        <ul class="nav navbar-nav" v-if="isAdmin">
          <li class="nav-item">
            <a
              @click="isPlannerOpened = false"
              href="#"
              class="navbar-brand"
              data-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="true"
            >
              KPI
            </a>
            <ul class="dropdown-menu bg-dark ps-3">
              <li class="nav-item">
                <router-link
                  @click="isPlannerOpened = false"
                  class="navbar-brand"
                  to="/performanceEvaluationAdministration"
                  v-if="isAdmin"
                  >KPI Kompanije</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  @click="isPlannerOpened = false"
                  class="navbar-brand"
                  to="/performanceEvaluationAdministrationLicni"
                  v-if="isAdmin"
                  >KPI Lični</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  @click="isPlannerOpened = false"
                  class="navbar-brand"
                  to="/performanceEvaluationAdministrationNew"
                  v-if="isAdmin"
                  >Dodaj novi</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  @click="isPlannerOpened = false"
                  class="navbar-brand"
                  to="/personalDevelopmentPlan"
                  v-if="isAdmin"
                  >Plan Licnog Razvoja</router-link
                >
              </li>
            </ul>
          </li>
        </ul>

        <ul class="nav navbar-nav" v-if="isAdmin">
          <li class="nav-item">
            <a
              @click="isPlannerOpened = false"
              href="#"
              class="navbar-brand"
              data-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="true"
            >
              Upitnici
            </a>
            <ul class="dropdown-menu bg-dark ps-3">
              <li class="nav-item">
                <router-link
                  @click="isPlannerOpened = false"
                  class="navbar-brand"
                  to="/questionnaire-creation"
                  v-if="isAdmin"
                  >Kreiraj upitnik</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  @click="isPlannerOpened = false"
                  class="navbar-brand"
                  to="/questionnaire-report"
                  v-if="isAdmin"
                  >Izveštaji upitnika</router-link
                >
              </li>
            </ul>
          </li>
        </ul>

        <ul class="nav navbar-nav" v-if="isAdmin">
          <li class="nav-item">
            <a
              href="#"
              class="navbar-brand"
              data-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="true"
            >
              Baza
            </a>
            <ul class="dropdown-menu bg-dark ps-3">
              <li class="nav-item">
                <router-link
                  @click="isPlannerOpened = false"
                  class="navbar-brand"
                  to="/bazaKorisnika"
                  v-if="isAdmin"
                  >Baza Korisnika</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  @click="isPlannerOpened = false"
                  class="navbar-brand"
                  to="/candidates"
                  v-if="isAdmin"
                  >Baza Kandidata</router-link
                >
              </li>
            </ul>
          </li>
        </ul>

        <li class="nav-item">
          <router-link
            @click="isPlannerOpened = false"
            class="navbar-brand"
            to="/formulari"
            v-if="isAdmin"
            >Formulari</router-link
          >
        </li>

        <li class="nav-item">
          <router-link
            @click="isPlannerOpened = true"
            class="navbar-brand"
            to="/planner"
            v-if="isAdmin"
            >Planner</router-link
          >
        </li>

        <ul class="nav navbar-nav" v-if="isAdmin">
          <li class="nav-item">
            <a
              href="#"
              class="navbar-brand"
              data-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="true"
            >
              Fakture
            </a>
            <ul class="dropdown-menu bg-dark ps-3">
              <li class="nav-item">
                <router-link class="navbar-brand" to="/faktura" v-if="isAdmin"
                  >Dodaj Fakturu</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="navbar-brand" to="/faktures" v-if="isAdmin"
                  >Sve Fakture</router-link
                >
              </li>
            </ul>
          </li>
        </ul>

        <li class="nav-item">
          <router-link
            class="navbar-brand"
            to="/eng"
            v-if="!isEng && !isLoggedIn"
          >
            <img id="eng" src="@/assets/engicon.png" alt="" />
          </router-link>
        </li>

        <li class="nav-item">
          <router-link class="navbar-brand" to="/" v-if="isEng && !isLoggedIn">
            <img id="srb" src="@/assets/srbicon.png" alt="" />
          </router-link>
        </li>

        <li class="nav-item ps-3 py-2 pointer">
          <a
            to="/logout"
            class="nav-link p-0 m-0"
            @click.prevent="logoutUser"
            v-if="isLoggedIn"
          >
            Logout
          </a>
        </li>
      </ul>
    </div>
    <div
      class="text-white float-end pe-5 d-flex flex-column text-center"
      v-if="isLoggedIn && !isAdmin"
    >
      <span class="small">Key Solutions Brošura</span>
      <a
        href="https://www.keysolutions.rs/wp-content/uploads/2023/04/Key-Solutions-Consulting-Korporativna-Brosura-2023.pdf"
        download="Brosura.pdf"
        target="_blank"
      >
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/121px-PDF_file_icon.svg.png"
          id="pdfBrosura"
      /></a>
    </div>
    <div
      v-if="isAdmin && isPlannerLocked && isPlannerOpened"
      class="planner-warning px-3 me-3 bg-danger py-2 rounded-3"
    >
      <span class="text-white ">Planer je zaključan</span>
    </div>
  </nav>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapGetters, mapActions } from "vuex";
import io from "socket.io-client";
export default {
  data() {
    return {
      isPlannerLocked: false,
      isPlannerOpened: false,
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn", "isAdmin", "isEng"]),
  },
  methods: {
    ...mapActions(["logout", "setEng", "setSrb"]),
    logoutUser() {
      this.userName = null;
      this.logout();
    },
    async updateEng() {
      await this.setEng();
    },
    async updateSrb() {
      await this.setSrb();
    },
  },
  async created() {
    this.socket = io("https://keysolutions.herokuapp.com");

    this.socket.on("plannerLocked", (message) => {
      this.isPlannerLocked = message;
    });
  },
};
</script>

<style lang="css" scoped>
.planner-warning {
  position: fixed;
  top: 1%;
  right: 0;
}

#srb {
  width: 30px;
}
#eng {
  width: 30px;
}
#pdfBrosura {
  animation: flash-animation 5s infinite;
  width: 30px;
}
@keyframes flash-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
.pointer {
  cursor: pointer;
}
</style>
